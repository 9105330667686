<lib-toast [toastMessage]="errorMessage" [error]="isError"></lib-toast>
<div class="toputil">

    <div class="left">
        <img src="/assets/images/logo.png" width="120" height="40" alt="">
    </div>
    <div class="right">
        <div class="flex-right">
            <div class="timezone">
                <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill" *ngIf="false">
                    <mat-select id="selectProdSystem" class="ig2" [(ngModel)]="selectedTimezone"
                        (selectionChange)="onTimezoneChange()">
                        <mat-option [value]="item.value" *ngFor="let item of timezones">
                            {{ item.name + ' (' + item.abbreviation + ')' }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="user-details">
                <ul class="topnav__list" name="topnav__list">
                    <div class="list">
                        <li>
                            <lib-icon name="bell_notification" theme="white" size="standard"></lib-icon>
                        </li>
                        <li class="topnav__list-item">
                            <div class="topnav__list-divider">
                            </div>
                        </li>
                        <li class="topnav__list-item" (mouseover)="showProfileInfo=true"
                            (mouseout)="showProfileInfo=false">
                            <img class="profile-pic" [src]="profilePicUrl" *ngIf="profilePicUrl" alt="" />
                            <div class="profile-pic-icon" *ngIf="!profilePicUrl"> <svg viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 13C15.0784 13 17.0753 13.8089 18.5677 15.2555C20.0602 16.702 20.931 18.6726 20.996 20.75L21 21H3C3 18.8783 3.84285 16.8434 5.34315 15.3431C6.84344 13.8429 8.87827 13 11 13H13ZM13 14H11C9.34845 14.0003 7.75015 14.5843 6.48754 15.6489C5.22493 16.7136 4.37927 18.1902 4.1 19.818L4.07 20H19.927L19.903 19.836C19.6342 18.2451 18.8243 16.7959 17.6102 15.7333C16.3962 14.6706 14.8525 14.0598 13.24 14.004L13 14ZM12 3C13.1935 3 14.3381 3.47411 15.182 4.31802C16.0259 5.16193 16.5 6.30653 16.5 7.5C16.5 8.69347 16.0259 9.83807 15.182 10.682C14.3381 11.5259 13.1935 12 12 12C10.8065 12 9.66193 11.5259 8.81802 10.682C7.97411 9.83807 7.5 8.69347 7.5 7.5C7.5 6.30653 7.97411 5.16193 8.81802 4.31802C9.66193 3.47411 10.8065 3 12 3ZM12 4C11.0717 4 10.1815 4.36875 9.52513 5.02513C8.86875 5.6815 8.5 6.57174 8.5 7.5C8.5 8.42826 8.86875 9.3185 9.52513 9.97487C10.1815 10.6313 11.0717 11 12 11C12.9283 11 13.8185 10.6313 14.4749 9.97487C15.1313 9.3185 15.5 8.42826 15.5 7.5C15.5 6.57174 15.1313 5.6815 14.4749 5.02513C13.8185 4.36875 12.9283 4 12 4Z"
                                        fill="#fff" />
                                    <path
                                        d="M13 13C15.0784 13 17.0753 13.8089 18.5677 15.2555C20.0602 16.702 20.931 18.6726 20.996 20.75L21 21H3C3 18.8783 3.84285 16.8434 5.34315 15.3431C6.84344 13.8429 8.87827 13 11 13H13ZM13 14H11C9.34845 14.0003 7.75015 14.5843 6.48754 15.6489C5.22493 16.7136 4.37927 18.1902 4.1 19.818L4.07 20H19.927L19.903 19.836C19.6342 18.2451 18.8243 16.7959 17.6102 15.7333C16.3962 14.6706 14.8525 14.0598 13.24 14.004L13 14ZM12 3C13.1935 3 14.3381 3.47411 15.182 4.31802C16.0259 5.16193 16.5 6.30653 16.5 7.5C16.5 8.69347 16.0259 9.83807 15.182 10.682C14.3381 11.5259 13.1935 12 12 12C10.8065 12 9.66193 11.5259 8.81802 10.682C7.97411 9.83807 7.5 8.69347 7.5 7.5C7.5 6.30653 7.97411 5.16193 8.81802 4.31802C9.66193 3.47411 10.8065 3 12 3ZM12 4C11.0717 4 10.1815 4.36875 9.52513 5.02513C8.86875 5.6815 8.5 6.57174 8.5 7.5C8.5 8.42826 8.86875 9.3185 9.52513 9.97487C10.1815 10.6313 11.0717 11 12 11C12.9283 11 13.8185 10.6313 14.4749 9.97487C15.1313 9.3185 15.5 8.42826 15.5 7.5C15.5 6.57174 15.1313 5.6815 14.4749 5.02513C13.8185 4.36875 12.9283 4 12 4Z"
                                        fill="#fff" />
                                </svg>
                            </div>
                        </li>
                    </div>
                </ul>
                <div [hidden]="!showProfileInfo" class="profile-info">
                    <mat-card>
                        <mat-card-title>{{ userName }}</mat-card-title>
                        <mat-card-subtitle>{{ officeName }}</mat-card-subtitle>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>