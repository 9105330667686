export const getTimezones: () => {
    name: string;
    abbreviation: string;
    value: string;
}[] = () => {
    let timezones: {
        name: string;
        abbreviation: string;
        value: string;
    }[] = [
        {
            name: "Pacific Time",
            abbreviation: "",
            value: "US/Pacific"
        },
        {
            name: "Arizona Time",
            abbreviation: "",
            value: "US/Arizona"
        },
        {
            name: "Mountain Time",
            abbreviation: "",
            value: "US/Mountain"
        },
        {
            name: "Central Time",
            abbreviation: "",
            value: "US/Central"
        },
        {
            name: "Eastern Time",
            abbreviation: "",
            value: "US/Eastern"
        },
        {
            name: "Hawaii Time",
            abbreviation: "",
            value: "US/Hawaii"
        },
        {
            name: "Alaska Time",
            abbreviation: "",
            value: "US/Alaska"
        },
        {
            name: "Indian Time",
            abbreviation: "",
            value: "Asia/Calcutta"
        },
    ];
    let local = {
        name: "Local Time",
        abbreviation: "",
        value: "local"
    };
    timezones = timezones.filter(t => t.value !== Intl.DateTimeFormat().resolvedOptions().timeZone);
    timezones = timezones.sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    timezones.unshift(local);
    timezones = timezones.map(t => { return {...t, abbreviation: getTimezoneAbbreviation(t.value)} });
    return timezones;
}

function getTimezoneAbbreviation(timezone: string): string{
    if(timezone.toLowerCase() === "local"){
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    let fullTimezone: string = (new Date().toLocaleDateString('en-US', { day: '2-digit', timeZoneName: 'long', timeZone: timezone }).slice(4));
    let abbreviation: string = fullTimezone.split(' ').map(word => word[0]).join('');
    return abbreviation;
}