(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@igps/client-root-config"));
	else if(typeof define === 'function' && define.amd)
		define(["@igps/client-root-config"], factory);
	else if(typeof exports === 'object')
		exports["TopUtilityBar-UI"] = factory(require("@igps/client-root-config"));
	else
		root["TopUtilityBar-UI"] = factory(root["@igps/client-root-config"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__946__) => {
return 