import { Component, EventEmitter, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { getUser, getFASTOffice, getToken, userDataObservable, getFASTUserStatus, getTimezone, setTimezone } from '@igps/client-root-config';
import { Subscription } from 'rxjs';
import { UserPhotoResponse } from 'src/models/user-photo-response';
import { GraphService } from 'src/services/graph.service';
import { FaIconsRegistry, faBellNotification } from '@ui-core/icon';
import { ToastComponent } from '@ui-core/toast';
import { getTimezones } from 'src/assets/timezones';

export const refreshNoteList = new EventEmitter<boolean>(false);
export const onChangeProcess: EventEmitter<{ id: string; name: string; processorAddTask: boolean; taskSource: number }> = new EventEmitter<{ id: string; name: string; processorAddTask: boolean; taskSource: number }>();
export const selectedTaskId: EventEmitter<string | null> = new EventEmitter<string | null>();
export const EmailTaskId: EventEmitter<{ id: string }> = new EventEmitter<{ id: string }>();
export const SearchResultTaskId: EventEmitter<string | null> = new EventEmitter<string | null>();
export const EmailMessageId: EventEmitter<string> = new EventEmitter<string>();
export const notesClickEvent: EventEmitter<string> = new EventEmitter<string>();
export const GetSelectedProcess: () => { id: string; name: string; processorAddTask: boolean; taskSource: number } = () => selectedProcess;
export const showToastEvent: EventEmitter<{ message: string; isError: Boolean }> = new EventEmitter<{ message: string; isError: Boolean }>();
export const GetEmailMessage: () => string = () => selectedEmailMessageId;
export const GetEmailTaskID: () => { id: string } = () => selectedEmailTaskId;

let selectedProcess!: { id: string; name: string; processorAddTask: boolean; taskSource: number };
let selectedEmailMessageId!: string;
let selectedEmailTaskId!: { id: string };

@Component({
  selector: 'client-toputility',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'igps-topbar';
  userName!: string;
  officeName!: string;
  fastuserstatus!: string;
  profilePicUrl!: SafeUrl;
  graphServiceSub$!: Subscription;
  SelectedProcessSub$!: Subscription;
  SelectedEmailMessageId$!: Subscription;
  SelectedEmailTaskId$!: Subscription;
  subscribeToastSub$!: Subscription;
  showProfileInfo: boolean = false;
  errorMessage!: string;
  isError!: boolean;
  @ViewChild(ToastComponent) toaster!: ToastComponent;
  selectedTimezone!: string;
  timezones: { name: string; abbreviation: string; value: string }[] = [];

  constructor(private faIconsRegistry: FaIconsRegistry,
    private graphService: GraphService,
    private sanitizer: DomSanitizer, private ngZone: NgZone
  ) {
    this.faIconsRegistry.registerIcons([faBellNotification]);
  }

  async ngOnInit(): Promise<void> {
    this.timezones = getTimezones();
    this.userName = getUser();
    this.officeName = getFASTOffice();
    this.fastuserstatus = getFASTUserStatus();
    const token = await getToken();
    this.getPhoto();
    this.showToast();
    this.SelectedProcessSub$ = onChangeProcess.subscribe(data => selectedProcess = data);
    this.SelectedEmailMessageId$ = EmailMessageId.subscribe(data => selectedEmailMessageId = data);
    this.SelectedEmailTaskId$ = EmailTaskId.subscribe(data => selectedEmailTaskId = data);


    switch (this.fastuserstatus) {
      case "NOTFOUND":
        this.openToast("User not found in FAST. Contact your supervisor.", false);
        break;
      case "SERVICEUNAVAILABLE":
        this.openToast("IgnitePS is experiencing a temporary disconnection from FAST.  Some functionality may be limited.", false);
        break;
      case "INACTIVE":
        this.openToast("User Inactive in FAST. Contact your supervisor.", false);
        break;
      default:
        break;
    }
    this.selectedTimezone = getTimezone().value;
    this.onTimezoneChange();
  }

  onTimezoneChange() {
    let timezone = this.timezones.find(t => t.value === this.selectedTimezone)!;
    setTimezone(timezone);
  }

  getPhoto() {
    this.graphServiceSub$ = this.graphService.getUserImage().subscribe((data: UserPhotoResponse) => {
      const url = 'data:image/png;base64, ' + data?.imageBase64;
      this.profilePicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }
  showToast() {
    this.subscribeToastSub$ = showToastEvent.subscribe(
      (value: any) => {
        if (value) {
          this.ngZone.run(() => {
            this.openToast(value.message, value.isError);
          });
        }
      }
    );
  }
  openToast(message: string, isError: boolean) {
    this.isError = isError;
    this.errorMessage = message;
    this.toaster.toasterTimeout();
  }
  ngOnDestroy(): void {
    this.graphServiceSub$.unsubscribe();
    this.SelectedProcessSub$.unsubscribe();
    this.subscribeToastSub$.unsubscribe();
    this.SelectedEmailMessageId$.unsubscribe();
    this.SelectedEmailTaskId$.unsubscribe();
  }
}
